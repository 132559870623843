body {
  &.d-m {
    #particles-js {
      background: $colorBgSec;
    }
    #home {
      .banner-holder {
        .hello {
          .name {
            color: $colorTextSec;
          }
          .hello-im {
            color: $colorTextSec;
          }
        }
      }
    }
    footer {
      &#home-footer {
        .social-footer {
          .sm-link {
            background: $colorBgPri;
            color: $colorTextPri;
          }
        }
      }
    }
  }
}

#particles-js {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  max-width: 100vw;
  max-height: 100vh;
  z-index: -1;
  background: $colorBgPri;
}

#home {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  .banner-holder {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .hello {
      width: auto;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: default;
      -webkit-transform: translateY(-4.8vw);
      -moz-transform: translateY(-4.8vw);
      -ms-transform: translateY(-4.8vw);
      -o-transform: translateY(-4.8vw);
      transform: translateY(-4.8vw);
      @include mobile {
        -webkit-transform: translateY(-5.8vw);
        -moz-transform: translateY(-5.8vw);
        -ms-transform: translateY(-5.8vw);
        -o-transform: translateY(-5.8vw);
        transform: translateY(-5.8vw);
      }
      @include tablet {
        -webkit-transform: translateY(-8vw);
        -moz-transform: translateY(-8vw);
        -ms-transform: translateY(-8vw);
        -o-transform: translateY(-8vw);
        transform: translateY(-8vw);
      }
      .name, .hello-im {
        margin: 0;
        &::selection {
          color: inherit;
          background: none;
        }
      }
      .name {
        text-transform: uppercase;
        font-size: 8vw;
        color: $colorTextPri;
        border-right: 3px solid transparent;
        -webkit-animation: hide 2000ms 1, blink-caret 500ms infinite 2000ms, typing 750ms steps(40, end) 2000ms;
        -o-animation: hide 2000ms 1, blink-caret 500ms infinite 2000ms, typing 750ms steps(40, end) 2000ms;
        animation: hide 2000ms 1, blink-caret 500ms infinite 2000ms, typing 750ms steps(40, end) 2000ms;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
        float: left;
        @include tablet {
          font-size: 10vw;
        }
        @include mobile {
          font-size: 14vw;
        }
      }
      .hello-im {
        text-transform: uppercase;
        font-size: 4.8vw;
        color: $colorTextPri;
        border-right: 3px solid transparent;
        -webkit-animation: hide 750ms 1, blink-caret 500ms 4, typing 750ms steps(40, end) 750ms;
        -o-animation: hide 750ms 1, blink-caret 500ms 4, typing 750ms steps(40, end) 750ms;
        animation: hide 750ms 1, blink-caret 500ms 4, typing 750ms steps(40, end) 750ms;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
        float: left;
        @include mobile {
          font-size: 5.8vw;
        }
        @include mobile {
          font-size: 8vw;
        }
      }
    }
  }
}

footer {
  &#home-footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .social-footer {
      width: auto;
      height: auto;
      padding: 10px;
      -webkit-border-radius: 10px 10px 0 0;
      -moz-border-radius: 10px 10px 0 0;
      border-radius: 10px 10px 0 0;
      background: transparent;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      -webkit-animation: hide-social-footer 3000ms 1, social-footer 800ms 1 3000ms;
      -o-animation: hide-social-footer 3000ms 1, social-footer 800ms 1 3000ms;
      animation: hide-social-footer 3000ms 1, social-footer 800ms 1 3000ms;
      .sm-link {
        background: $colorBgPri;
        width: 40px;
        height: 40px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        position: relative;
        font-size: 20px;
        @include mobile {
          width: 48px;
          height: 48px;
          font-size: 22px;
        }
        @include tablet {
          width: 56px;
          height: 56px;
          font-size: 28px;
        }
        &:hover ~ .social-tooltip {
          -webkit-transition: opacity 200ms ease-in-out;
          -moz-transition: opacity 200ms ease-in-out;
          -ms-transition: opacity 200ms ease-in-out;
          -o-transition: opacity 200ms ease-in-out;
          transition: opacity 200ms ease-in-out;
        }
        &.twitter {
          background: $colorBrandTwitter;
          color: #FFFFFF;
          &:hover ~ .social-tooltip {
            opacity: 1;
            &:before {
            content: "@berkesutcu";
            }
          }
        }
        &.insta {
          background: linear-gradient(#400080, transparent), linear-gradient(200deg, #d047d1, #ff0000, #ffff00);
          color: #FFFFFF;
          &:hover ~ .social-tooltip {
            opacity: 1;
            &:before {
              content: "@berkesutcu";
            }
          }
        }
        &.mail {
          background: $colorBgSec;
          color: $colorTextSec;
          &:hover ~ .social-tooltip {
            opacity: 1;
            &:before {
              content: "hello@berke.xyz";
            }
          }
        }
      }
      .social-tooltip {
        position: absolute;
        top: -60%;
        left: 0;
        width: 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        &:before {
          content: '';
          padding: 5px 10px;
          background: $colorBgPri;
          color: $colorTextPri;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
        }
      }
    }
  }
}