@mixin mobile {
  @media screen and (max-width: 480px) {
    @content
  }
}

@mixin tablet {
  @media screen and (max-width: 768px) {
    @content
  }
}

@mixin desktop {
  @media screen and (max-width: 992px) {
    @content
  }
}

@mixin tablet {
  @media screen and (max-width: 1200px) {
    @content
  }
}